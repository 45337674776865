
<div fxLayout="row wrap">
    <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
        <h2 class="page-title m-0">stock สินค้า</h2>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <mat-card>
        <mat-card-content>
          <div class="align-items-center">
            <div fxFlex="ึ80">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา">
                <mat-icon (keyup)="applyFilter($event.target.value)" matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  
  <app-blank *ngIf="this.stockLength === 0"></app-blank>


  <div fxLayout="row wrap" *ngIf="this.stockLength !== 0">
    <div fxFlex="20" style="padding-right: 20px; margin-left: 20px;">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <select matNativeControl (change)="selectStatus($event)">
            <option *ngFor="let option of statusList;" [value]="option.value"
                    [selected]="selectedStatus === option.value">{{ option.text }}</option>
          </select>
        </mat-form-field>
      </div>
      <div fxFlex="20" style="padding-right: 20px;">
        <mat-form-field>
          <mat-label>Sort By</mat-label>
          <select matNativeControl (change)="selectSort($event)">
            <option *ngFor="let option of sortList;" [value]="option.value"
                    [selected]="selectedSort === option.value">{{ option.text }}</option>
          </select>
        </mat-form-field>
      </div>
      <div fxFlex="20" style="padding-right: 20px;">
        <mat-form-field>
          <mat-label>Arrange By</mat-label>
          <select matNativeControl (change)="selectSortBy($event)" [(ngModel)]="selectedSortBy">
            <option value="asc">ASC</option>
            <option value="desc">DESC</option>
          </select>
        </mat-form-field>
      </div>
      <div fxFlex="40" class="text-right">
      </div>
    <div fxFlex="100">
    <mat-card>
        <mat-card-content>
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> index </th>
                <td mat-cell *matCellDef="let item; let i = index;">  {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
            </ng-container>


            <ng-container matColumnDef="stockLocation">
                <th mat-header-cell *matHeaderCellDef> ชื่อสินค้า </th>
                <td mat-cell *matCellDef="let element" > <span class="cursor-pointer" (click)="navigateToProductDetail(element)">{{element.title || '-'}} ({{ element.Colors[0]?.title }})</span></td>
            </ng-container>

            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef> รหัสสินค้า </th>
              <td mat-cell *matCellDef="let item; let i = index;"> 
                <ng-container *ngIf="!!item?.imgList">
                  <img *ngIf="!!item?.imgList[0]" src="{{ item?.imgList[0]?.img }}" width="50"  height="50">
                  <img *ngIf="!item?.imgList[0]" style="background-color: grey;" width="50"  height="50">
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef> size </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let data of element.stockList">
                        <div>
                            {{ displaySize(element, data.size) }}
                        </div>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="stock">
              <th mat-header-cell *matHeaderCellDef> preorderDay </th>
              <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let data of element.stockList">
                      <div *ngIf="data?.stock?.warehouse === 'Preorder'">
                          {{ data?.stock?.preOrderDays }}
                      </div>
                      <div *ngIf="data?.stock?.warehouse !== 'Preorder'">
                        -
                    </div>
                  </ng-container>
              </td>
          </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef> warehouse</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let data of element.stockList">
                        <div> <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-primary': data.stock.warehouse === 'Office', 'label-warning': data.stock.warehouse === 'Warehouse', 'label-danger': data.stock.warehouse === 'Preorder'}">{{ data.stock.warehouse }}</span></div>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="quantityLeft">
                <th mat-header-cell *matHeaderCellDef> quantity</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let data of element.stockList">
                        <div>{{ data.stock.no }}</div>
                    </ng-container>
                </td>
            </ng-container>


            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> action </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let data of element.stockList">
                        <div><a (click)="gotoEdit(element,data)" class="m-r-10 cursor-pointer text-dark">edit</a>
                        <!-- <a (click)="gotoDelete(element,data)" class="m-r-10 cursor-pointer text-dark">delete</a> -->
                      </div>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="pagination" [length]="stockLength" [pageSize]="tableOptions.pageSize"
            [pageSizeOptions]="pageSizeOptions" [hidePageSize]="true" (page)="onPageChanged($event)">
            </mat-paginator>
        </div>
        </mat-card-content>
        </mat-card>
    </div>
</div>


<!-- <mat-accordion>
  <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
          <mat-panel-title> Stock </mat-panel-title>
          <mat-panel-description> Quantity </mat-panel-description>
      </mat-expansion-panel-header>
      <app-blank *ngIf="this.stockLength === 0"></app-blank>
  </mat-expansion-panel>
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title> Stock </mat-panel-title>
          <mat-panel-description> Location  </mat-panel-description>
      </mat-expansion-panel-header>
    <app-blank *ngIf="this.stockLocationLength === 0"></app-blank>
    <div fxLayout="row wrap" *ngIf="this.stockLocationLength !== 0">
        <div fxFlex="100">
        <mat-card>
            <mat-card-content>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource2" class="table employee-list no-wrap">
    
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> index </th>
                    <td mat-cell *matCellDef="let item; let i = index;"> {{ i + (tableOptions2.pageNumber * tableOptions2.pageSize) + 1 }} </td>
                </ng-container>
    
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> title </th>
                    <td mat-cell *matCellDef="let element"> {{element.title || '-'}} </td>
                </ng-container>
    
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                </table>
                <mat-paginator class="pagination" [length]="stockLocationLength" [pageSize]="tableOptions2.pageSize"
                [pageSizeOptions]="pageSizeOptions2" [hidePageSize]="true" (page)="onPageChanged2($event)">
                </mat-paginator>
            </div>
            </mat-card-content>
            </mat-card>
        </div>
    </div>
  </mat-expansion-panel>
</mat-accordion> -->
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StocksService } from '../services/stock.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import { SORTORDER, StockLocationReq, StockReq } from '../../../client';
import { cloneDeep as _cloneDeep } from 'lodash';
import { ALL_SHOE_SIZE, CLOTHE_SIZE, PRODUCT_TYPE, SORT_LIST, STATUS_LIST } from '../common/constants';
import { environment } from '../../../src/environments/environment';
import * as _ from 'lodash';
import { createImportSpecifier } from 'typescript';
@Component({
  selector: 'app-admin-stock',
  templateUrl: './admin-stock.component.html',
  styleUrls: ['./admin-stock.component.scss']
})
export class AdminStockComponent implements OnInit {
  displayedColumns: string[] = ['index','stockLocation', 'image', 'size', 'stock', 'quantity', 'quantityLeft', 'title'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  stockLength: any;

  displayedColumns2: string[] = ['index', 'title'];
  dataSource2 = new MatTableDataSource();
  tableOptions2: TableDataSource;
  pageSizeOptions2 = ['10', '20', '30'];
  stockLocationLength: any;
  searchName: any = null;

  sortList = SORT_LIST;
  statusList = STATUS_LIST;
  selectedStatus = '';
  selectedSort = 'createdAt';
  selectedSortBy= SORTORDER.Desc;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(public dialog: MatDialog,
    public router: Router,
    public alertService: AlertService,
    public stockService: StocksService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.setupTableOptions2();
    this.start();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: this.selectedSortBy,
      sortBy: this.selectedSort
    };
  }

  setupTableOptions2() {
    this.tableOptions2 = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  start() {
    this.getStock();
    this.getStockLocation();
  }

  async getStock(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.setupTableOptions();
    }
    this.tableOptions.sortOrder = this.selectedSortBy;
    this.tableOptions.sortBy = this.selectedSort;
    const result: any = await this.stockService.getStock(this.searchName, this.tableOptions);
    if (result.success) {
      const newModel = result.payload.products.map(ff => {
        let nn = [];
        Object.keys(ff.groupStockBySize).map(function(key, index) {
          let bb = [];
          Object.keys(ff.groupStockBySize[key]).map(function(keys, index) {
            if(ff.Stocks.find(vv => vv.StockLocation.title === keys && vv.size === key)) {
              nn.push({
                size: key,
                sortingKey: ff.Stocks.find(ed => ed.size === key)?.sortingKey,
                sortingFirstKey: ff.Stocks.find(vv => vv.StockLocation.title === keys && vv.size === key).StockLocationId,
                stock: {
                  warehouse: keys,
                  no: ff.groupStockBySize[key][keys],
                  preOrderDays: ff.Stocks.find(ed => ed.size === key && ed.StockLocation.title === 'Preorder')?.preOrderDays
                }
              });
            }
          })
          ff['stockList'] = nn;
        });
        ff['imgList'] = ff.ProductImgs.sort((a, b) => a.order - b.order).map((ff) => {
          return {
             img: `${environment.backendUri}/image/` + ff?.id,
          }
        });
        return ff;
      });
      const sortByLocation = newModel.map(it => {
        const filterStock1 = _.orderBy(it.stockList?.filter(ff => ff.sortingFirstKey === 1), ['sortingKey'], ['asc']);
        const filterStock2 = _.orderBy(it.stockList?.filter(ff => ff.sortingFirstKey === 2), ['sortingKey'], ['asc']);
        const filterStock3 = _.orderBy(it.stockList?.filter(ff => ff.sortingFirstKey === 3), ['sortingKey'], ['asc']);
        it.stockList = filterStock2.concat(filterStock1).concat(filterStock3)
        return it;
      });
      this.dataSource = new MatTableDataSource(sortByLocation);
      this.dataSource._updateChangeSubscription();
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
    }
      this.stockLength = result.payload.listLength;
    }
  }

  async getStockLocation(pageIndex?: number) {
    if (pageIndex) {
      this.tableOptions2.pageNumber = pageIndex;
    } else {
      this.setupTableOptions2();
    }
    const result: any = await this.stockService.getStockLocation(this.tableOptions2);
    if (result.success) {
      this.dataSource2 = new MatTableDataSource(result.payload.stockLocations);
      this.dataSource2._updateChangeSubscription();
      this.stockLocationLength = result.payload.listLength;
    }
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.getStock(pageObj.pageIndex);
  }

  onPageChanged2(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions2.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions2();
  }
    this.getStockLocation(pageObj.pageIndex);
  }

  async gotoDelete(data,detail) {
    const stockLo = data.Stocks.find(it => it.size === detail.size && it.StockLocation.title === detail.stock.warehouse);
    const stockId = stockLo?.id;
    const result = await this.stockService.deleteStockByStockId(stockId);
    if(result) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getStock(0);
    }
  }

  gotoEdit(data,detail) {
    const stockLo = data.Stocks.find(it => it.size === detail.size && it.StockLocation.title === detail.stock.warehouse);
    const stockId = stockLo?.id;
    const stockReq = {
      size: detail.size,
      StockLocationId: stockLo?.StockLocationId,
      ProductId: data.id,
      quantity: detail.stock.no,
      preOrderDays: stockLo?.preOrderDays || 0,
      detail: detail
    };

    const dialogRef = this.dialog.open(UpdateStockDialogContent, {
      data: ['Update', stockReq]
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(!!!result || result.event === 'Cancel') {
        return;
      }
      this.updateStock(stockId, result.data);
    });
  }

  async updateStock(stockId, data) {
    delete data['detail'];
    const result = await this.stockService.updateStockByStockId(stockId, data);
    if(result) {
      this.getStock(this.tableOptions.pageNumber);
    }
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
         this.searchName = null;
         this.getStock(0);
      }

    if (data.length > 0) {
      this.searchName = data;
      this.getStock(0);
    }
  }

  selectSort(e) {
    this.selectedSort = e.target.value;
    this.getStock(0);
  }

  selectSortBy(e) {
    this.selectedSortBy = e.target.value;
    this.getStock(0);
  }

  selectStatus(e){
    this.selectedStatus = e.target.value;
    this.getStock(0);
  }

  async navigateToProductDetail(data) {
    if(!!data) {
      this.router.navigateByUrl(`/dashboard/products/edit/${data.id}`);
    }
  }

  displaySize(element, size) {
    return element.product_type === PRODUCT_TYPE.CLOTHES ? CLOTHE_SIZE.find(dd => dd.value === size)?.text : ALL_SHOE_SIZE.find(dd => dd === size)
  }

}


@Component({
  selector: 'add-update-stock',
  templateUrl: 'update-stock-dialog.html',
})
export class UpdateStockDialogContent {
  local_data: any;
  local_dataClone: any;
  action;
  constructor(
      public dialogRef: MatDialogRef<UpdateStockDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      this.local_data = data[1];
      this.local_dataClone = _cloneDeep(data[1]);
      this.action = data[0];
  }

  doAction() {
    if(!this.local_data?.quantity) {
      this.local_data['quantity'] = 0;
    }
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  // checkDisabled(data) {
  //   if (!!this.local_data.quantity) {
  //     return false;
  //   } else {
  //       return true;
  //   }
  // }

  validateWhite(val) {
    console.log(val);
  }

}

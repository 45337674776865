import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, empty, from, throwError } from 'rxjs';
import { tap, switchMap, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DialogsService } from '../../services/dialog/dialog.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private token: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private dialogService: DialogsService
    ) { }

    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
        return empty();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // return from(this.authService.getToken()).pipe(
        //     map(token => {
        //         if (token && token !== '') {
        //             const headers = req.headers
        //                 .set('Authorization', 'Bearer ' + token);
        //             const requestClone = req.clone({headers});
        //             return next.handle(requestClone).pipe(tap(
        //                 succ => {}, // If Token is still alright then do nothing
        //                 err => {
        //                     if (err.status === 401 || err.status === 403) {
        //                         this.logout();
        //                     }
        //                 }
        //             ));
        //         } else {
        //             const headers = req.headers;
        //             const requestClone = req.clone({headers});
        //             return next.handle(requestClone).pipe(tap(
        //                 succ => {}, // If Token is still alright then do nothing
        //                 err => {}
        //             ));
        //         }
        //     }),
        //     catchError((err: any, caught: Observable<any>) => {
        //         return throwError(this.generalErrorHandler(err, caught));
        //     }));
        
            
            
       return from(this.authService.getToken()).pipe(switchMap(token => {
            if (token && token !== '') {
                const headers = req.headers
                    .set('Authorization', 'Bearer ' + token);
                const requestClone = req.clone({headers});
                return next.handle(requestClone).pipe(tap(
                    succ => {}, // If Token is still alright then do nothing
                    err => {
                        console.log(err)
                        if((err.status === 401 || err.status === 403 || err.status === 422 || err.status === 500) && (err?.error?.name === 'NoAuthorization' || err.error?.message === 'jwt expired')) {
                          console.log(err.error);
                          const data = {
                              title: 'คำเตือน',
                              message: 'session ของคุณหมดอายุ กรุณา login ใหม่',
                              cancelText: 'cancel',
                              confirmText: 'ok'
                          };

                          this.dialogService.open(data);
                          this.dialogService.confirmed().subscribe((result) => {
                              if (!!result) {
                                  this.authService.logout();
                                  this.router.navigate(['/login']);
                              }
                          });

                        }
                    }
                ));
            } else {
                const headers = req.headers;
                const requestClone = req.clone({headers});
                return next.handle(requestClone).pipe(tap(
                    succ => {}, // If Token is still alright then do nothing
                    err => {
                        console.log(err)
                        if((err.error?.status === 401 || err.error?.status === 403 || err.error?.status === 422 || err.error?.status === 500) && (err?.error?.name === 'NoAuthorization' || err.error?.message === 'jwt expired')) {
                          console.log(err.error);
                          const data = {
                              title: 'คำเตือน',
                              message: 'session ของคุณหมดอายุ กรุณา login ใหม่',
                              cancelText: 'cancel',
                              confirmText: 'ok'
                          };

                          this.dialogService.open(data);
                          this.dialogService.confirmed().subscribe((result) => {
                              if (!!result) {
                                  this.authService.logout();
                                  this.router.navigate(['/login']);
                              }
                          });

                        }
                    }
                ));
            }

        }));
    }

    generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
        console.log('error caught: ', error);
        if( error.error.status == "INVALID_TOKEN" || error.error.status == "MAX_TOKEN_ISSUE_REACHED"){
          console.log('token has expired');
          this.logout();
          return error;
        }
        return error;
      }
}

import { Component, OnInit, Inject, Optional, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { AlertService } from '../services/alert.service';
import { USER_TYPE } from '../common/constants';
import {Router} from '@angular/router';
import { UserUpdateReq, InlineResponse200Payload, AddressAddReq, AddressRemoveReq, AddressUpdateReq } from '../../../client';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss'],
})


export class AdminUserComponent implements OnInit, AfterViewInit {
  constructor(public dialog: MatDialog,
              public router: Router,
              public alertService: AlertService,
              public userService: UserService) { }

  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  selectedCustomer = 'username';
  displayedColumns: string[] = ['in', 'index', 'firstName', 'lastName', 'username', 'role', 'type', 'status', 'createdAt', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  userLength: any;
  searchModel;
  dashboard: InlineResponse200Payload;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  ngOnInit() {
    this.searchModel = {
      email: null,
      firstname: null,
      lastname: null
    };
    this.setupTableOptions();
    this.start();
  }

  start() {
    this.getUser();
    this.getUserDashboard();
  }

  async getUser(pageIndex?: number) {
    if (pageIndex) {
        this.tableOptions.pageNumber = pageIndex;
      } else {
        this.tableOptions.pageNumber = 0;
      }
      const result: any = await this.userService.getUserByType(USER_TYPE.CUSTOMER, '', this.tableOptions);
      if (result.success) {
        this.dataSource = new MatTableDataSource(result.payload.users);
        this.dataSource._updateChangeSubscription();
        this.userLength = result.payload.userLength;
        if(this.tableOptions.pageNumber === 0) {
          this.paginator?.firstPage();
        }
      }
  }

  async getUserDashboard() {
    const result: any = await this.userService.getUserDasshbaord();
    if (result) {
      this.dashboard = result;
    }
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  async applyFilter(data, pageIndex?: any) {
    if (pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.tableOptions.pageNumber = 0;
    }
    if (data.length === 0) {
          this.searchModel = {
            email: null,
            firstname: null,
            lastname: null
          };
          this.getUser(0);
          this.searchText = null;
      } else {
        this.searchText = data;
      }

    if (data.length > 0) {
      this.searchModel = {
        email: this.selectedCustomer === 'username' ? data : null,
        firstname: this.selectedCustomer === 'firstname' ? data : null,
        lastname: this.selectedCustomer === 'lastname' ? data : null,
        contactNum: this.selectedCustomer === 'contactNum' ? data : null
      };
      const result = await this.userService.getUserByTypeAndSearchData(USER_TYPE.CUSTOMER, this.searchModel.email, this.searchModel.firstname, this.searchModel.lastname, this.searchModel.contactNum, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.users);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          if (result.users.length === 0) {
              this.userLength = 0;
          } else {
              this.userLength = result.userLength;
          }
      } else {
          this.userLength = 0;
      }
    }
  }

  selectedCustomerSearch(e) {
    this.selectedCustomer = e.target.value;
    this.searchText = null;
    this.setupTableOptions();
    this.searchModel = {
      email: this.selectedCustomer === 'username' ? this.searchText : null,
      firstname: this.selectedCustomer === 'firstname' ? this.searchText : null,
      lastname: this.selectedCustomer === 'lastname' ? this.searchText : null,
      contactNum: this.selectedCustomer === 'contactNum' ? this.searchText : null
    };
    this.getUser();
  }

  ngAfterViewInit() {
      // this.dataSource.paginator = this.paginator;
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
    } else {
        this.setupTableOptions();
    }
    this.tableOptions.pageSize = pageObj.pageSize;
    if(!!!this.searchText) {
      this.getUser(pageObj.pageIndex);
    } else {
      this.applyFilter(this.searchText, pageObj.pageIndex);
    }
  }

  openDialog(action: string, obj: any) {
    if(action === 'Address') {
      this.openAddress(obj);
      return;
    }

    if(action === 'View') {
      this.router.navigate(['/dashboard/customer/'+obj.id]);
      return;
    }
    obj.action = action;
    obj.role = 'user';
    const dialogRef = this.dialog.open(UserDialogContent, {
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!!!result) {
        return;
      }
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        } else if (result.event === 'Reset') {
          this.ResetPassword(result.data);
      }  else if (result.event === 'Score') {
        this.updateMemberScore(result.data);
      }
    });
  }

  openAddress(obj) {
    const dialogRef = this.dialog.open(AddressDialogContent, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!!!result) {
        return;
      }
      this.updateAddress(result.data);
    });
  }

  updateAddress(obj) {

  }

  // tslint:disable-next-line:variable-name
  async addRowData(row_obj: any) {
    row_obj.user_type = USER_TYPE.CUSTOMER;
    row_obj.username = null;
    row_obj['isAgreeTerms'] = true;
    const data = await this.userService.createCustomer(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getUser();
     }

  }
  // tslint:disable-next-line:variable-name
  async updateRowData(row_obj: any) {
    const req = {
      user_type: row_obj.user_type,
      bodyProportion: {
        bust: row_obj.bodyProportion.bust,
        waist: row_obj.bodyProportion.waist,
        hip: row_obj.bodyProportion.hip,
        height: row_obj.bodyProportion.height
      },
      settings: {
        defaultLanguage: row_obj.settings.defaultLanguage
      },
      dob: new Date(row_obj.dob).toString(),
      contactNum: row_obj.contactNum || '-',
      email: row_obj.email,
      username: row_obj.username,
      nickName: row_obj.nickName,
      lastName: row_obj.lastName,
      firstName: row_obj.firstName,
      active: row_obj.active
    } as UserUpdateReq;
    const data = await this.userService.updateUser(row_obj.id, req);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getUser(this.tableOptions.pageNumber);
    }
  }
  // tslint:disable-next-line:variable-name
  async deleteRowData(row_obj: any) {
    const data = await this.userService.deleteuser(row_obj);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getUser(this.tableOptions.pageNumber);
    }
  }

  async ResetPassword(row_obj: any) {
    const data = await this.userService.resetPassByAdmin(row_obj);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getUser(this.tableOptions.pageNumber);
    }
  }

  async updateMemberScore(data) {
    let model = data;
    model['userId'] = data.UserId;
    delete model.UserId;
    delete model.id;
    const result = await this.userService.updateMemberScore(data);
    if (result) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getUser(this.tableOptions.pageNumber);
    }
  }
}



import { MatDateFormats, MAT_NATIVE_DATE_FORMATS, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'user-dialog',
  templateUrl: 'user-dialog.html',
  providers: [
      { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ]
})
// tslint:disable-next-line: component-class-suffix
export class UserDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  password: string;
  member;
  role;
  constructor(
      public dialogRef: MatDialogRef<UserDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      if(data.action === 'Add') {
        data.settings = {
          defaultLanguage: 'EN'
        };
        data.bodyProportion = {
          bust: '0',
          waist: '0',
          hip: '0',
          height: 0,
        };
        data.active = true; 
      } else {
        data.dob = data.dob;
      }
      this.local_data = { ...data };
      this.member = this.local_data.Member;
      this.local_data.dobtxt = moment(new Date(data.dob)).format("DD/MM/YYYY");
      this.action = this.local_data.action;
      this.role = this.local_data.role;
  }

  doAction() {
    if(this.action === 'Reset') {
      const data = {
        userId: this.local_data.id,
        newPass: this.password
      };
      this.dialogRef.close({ event: this.action, data:  data});
    } else  if(this.action === 'Score') {
      this.dialogRef.close({ event: this.action, data:  this.member});
    } else {
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.active = event;
  }

  addEvent(event) {
  }

  addEventExpired(event){

  }

  checkDisabled(data) {
    if (data.action === 'Add' || data.action === 'Update') {
        if (!!data.email && !!data.dob && !!data.firstName && !!data.lastName) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Reset') {
      if (!!this.password) {
        return false;
      } else {
          return true;
      }
    } else if(data.action === 'Score') {
      if (!!this.member.memberScore && !!this.member.memberType) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }

}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'address-dialog',
  templateUrl: 'address-dialog.html',
  styleUrls: ['./admin-user.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class AddressDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  user: any;
  addressForm: FormGroup;
  countries;
  isNew = false;
  isOpen = false;
  isHover = false;
  isHover2 = false;
  isHover3 = false;
  isHover4 = false;
  isHover5 = false;
  isWarning = false;
  
  constructor(
      public formBuilder: FormBuilder,
      private authService: AuthService,
      private userService: UserService,
      private alertService: AlertService,
      public dialogRef: MatDialogRef<AddressDialogContent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      this.local_data = { ...data };
      this.user = data;
      this.addressForm = this.formBuilder.group({
        UserId: [],
        address: ['', Validators.required],
        addressFull: [],
        addressLang: [],
        country: ['TH', Validators.required],
        address2: [],
        id: [],
        isPrimaryAddress:  [],
        province: ['', Validators.required],
        zipcode: ['', Validators.required]
      });
      this.getCountries();
      this.resetAddress();
  }

  async getCountries() {
    const data = await this.authService.getCountries();
    this.countries = JSON.parse(data);
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  checkDisabled(data) {
    if (!!this.user) {
      return false;
    } else {
        return true;
    }
  }

  displayCountry(codeName) {
    return !!codeName && !!this.countries ? this.countries.find(it => it.code === codeName)?.name: null;
  }

  onCancelAddress(data) {
    this.resetAddress();
  }

  async onUpdateAddress(data) {
    if(this.addressForm.invalid) {
      return;
    }
    
    if(data.id === this.addressForm.get('id').value) {
      data.id = this.addressForm.get('id').value;
      data.addressFull = this.addressForm.get('addressFull').value;
      data.address = this.addressForm.get('address').value;
      data.province = this.addressForm.get('province').value;
      data.address2 = this.addressForm.get('address2').value;
      data.zipcode = this.addressForm.get('zipcode').value.toString();
      data.country = this.addressForm.get('country').value;
      data.isPrimaryAddress = this.addressForm.get('isPrimaryAddress').value;
    }

    const updateAddress = await this.userService.updateAddress({address: data});
    if (updateAddress) {
      this.resetAddress();
      this.updateProfile();
    }
  }

  resetAddress() {
    this.user.Addresses = this.user.Addresses.map(it => {
      return {
        ...it,
        isShow: false
      }
    });
    this.addressForm.reset();
    this.addressForm.get('country').setValue('TH');
    this.addressForm.updateValueAndValidity();
  }

  onEditAddress(data) {
    this.isNew = false;
    this.resetAddress();
    const findData = this.user.Addresses.find(it => it.id === data.id);
    if(findData) {
      findData.isShow = true;
      this.addressForm.patchValue(data);
    }
  }


  addAddress() {
    this.resetAddress();
    this.isNew = true;
  }

  onCancel() {
    this.resetAddress();
    this.isNew = false;
  }

  async onCreate() {
    if(this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
      this.addressForm.updateValueAndValidity();
      return;
    }
    const data = {
      userId: this.user.id,
      addresses: [{
        addressFull: '',
        addressLang: 'EN',
        address: this.addressForm.get('address').value,
        province: this.addressForm.get('province').value,
        address2: this.addressForm.get('address2').value,
        zipcode: this.addressForm.get('zipcode').value?.toString(),
        country: this.addressForm.get('country').value,
        isPrimaryAddress: this.addressForm.get('isPrimaryAddress').value,
      }]
    } as AddressAddReq;

    const result = await this.userService.createAddress(data) as any;
    if(result) {
      this.alertService.success('ทำรายการสำเร็จ');
      const newModel = result.payload.addresses;
      this.user.Addresses = this.user.Addresses.concat(newModel);
      this.resetAddress();
      this.updateProfile();
    }
  }

  async onRemove(data) {
      const removeModel = {
        userId: this.user.id,
        addressIds: [data.id.toString()]
      } as AddressRemoveReq;

      const result = await this.userService.deleteAddress(removeModel);
      if(result) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.user.Addresses = this.user.Addresses.filter(it => it.id !== data.id);
        this.resetAddress();
        this.updateProfile();
      }
  }

  async updateProfile() {
    // const result = await this.userService.updateUser(data.id, data) as any;
    // if(result) {
    //   localStorage.setItem('user', JSON.stringify(result.payload.user));
    //   this.resetAddress();
    //   this.isOpen = false;
    // }
    this.dialogRef.close({ event: 'Cancel' });
  }
}





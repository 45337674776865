
<div fxLayout="row wrap">
  <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
      <h2 *ngIf="isAdmin" class="page-title m-0">รายการขาย 
        <small class="page-title m-0">(ทั้งหมด {{ userLength }} รายการ, มูลค่าทั้งหมด {{ orderSum | number }} บาท</small>)</h2>

        <h2 *ngIf="!isAdmin" class="page-title m-0">รายการขาย 
          <small class="page-title m-0">(ทั้งหมด {{ userLength }} รายการ)</small></h2>
  </div>
</div>
<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="align-items-center">
          <div fxFlex="80">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหา invoice">
              <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
            </mat-form-field>
          </div>
          <div fxFlex="20" class="text-center">
            <!-- <button mat-raised-button (click)="openDialog('Add',{})" color="primary">เพิ่มสินค้าใหม่</button> -->
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row wrap">
  <div class="col-3">
    <mat-form-field>
      <mat-label>Delivery Method</mat-label>
      <select matNativeControl (change)="selectedDeliveryMethod($event)" [(ngModel)]="selectedDelivery">
        <option value="">ทั้งหมด</option>
        <option value="DELIVERY">DELIVERY</option>
        <option value="PICK_UP">PICKUP</option>
      </select>
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Order Status</mat-label>
      <select matNativeControl (change)="selectSortByed($event)" [(ngModel)]="selectedOrderStatus">
        <option value="">ทั้งหมด</option>
        <option value="SUCCESS">สำเร็จ</option>
        <option value="PENDING_DLVR">นำส่ง</option>
        <option value="CANCEL">ยกเลิก</option>
      </select>
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Payment Status</mat-label>
      <select matNativeControl (change)="selectedPaymentBy($event)" [(ngModel)]="selectedPayment">
        <option value="">ทั้งหมด</option>
        <option value="SUCCESS">สำเร็จ</option>
        <option value="PENDING">รอจ่ายเงิน</option>
        <option value="CANCEL">ยกเลิก</option>
      </select>
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Order Source</mat-label>
      <select matNativeControl (change)="selectedSourceBy($event)" [(ngModel)]="selectedSource">
        <option value="">ทั้งหมด</option>
        <option value="ONLINE">online</option>
        <option value="MANUAL">store</option>
      </select>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row wrap" *ngIf="this.userLength !== 0">                
  <div fxFlex="20" style="padding-left: 20px;" class="text-right">
    <mat-form-field>
      <mat-label>Sort By</mat-label>
      <select matNativeControl (change)="selectSort($event)">
        <option *ngFor="let option of sortList;" [value]="option.value"
                [selected]="selectedSort === option.value">{{ option.text }}</option>
      </select>
    </mat-form-field>
  </div>
  <div fxFlex="20" style="padding-left: 20px;" class="text-right">
    <mat-form-field>
      <mat-label>Arrange By</mat-label>
      <select matNativeControl (change)="selectSortBy($event)" [(ngModel)]="selectedSortBy">
        <option value="asc">ASC</option>
        <option value="desc">DESC</option>
      </select>
    </mat-form-field>
  </div>
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> วันที่ </th>
              <td mat-cell *matCellDef="let item; let i = index;"> {{ item?.confirmDate }} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> รายการ </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <div class="m-l-15">
                    <a (click)="gotoDetail(element)" target="_blank">
                      <p class="font-bold m-b-0 m-t-0 cursor-pointer" [ngClass]="{'bg-preorder': element.isPre, 'bg-online-manual': element.isOnlineManual  }">
                        {{ element.name }}</p>
                      </a>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="customer">
              <th mat-header-cell *matHeaderCellDef> ชื่อผู้ส่ง </th>
              <td mat-cell *matCellDef="let element">
                <a (click)="gotoCustomerDetail(element)" target="_blank"><p class="font-bold m-b-0 m-t-0 cursor-pointer">{{ element.customerName }}</p></a>
                </td>
            </ng-container>

            <ng-container matColumnDef="zipcode">
              <th mat-header-cell *matHeaderCellDef> zipcode </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.zipCode }}
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef> มูลค่า </th>
              <td mat-cell *matCellDef="let element"> {{ element.price | number }} </td>
            </ng-container>

            <ng-container matColumnDef="orderDate">
              <th mat-header-cell *matHeaderCellDef> วันที่ส่งสินค้า </th>
              <td mat-cell *matCellDef="let element"> {{element.orderDate | date: 'dd/MM/yyyy': 'UTC+7' }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> สถานะ </th>
              <td mat-cell *matCellDef="let element">
               <ng-container *ngIf="element?.delivery?.deliveryMethod !== 'PICK_UP'">
                  <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-warning' : element.active === 'PENDING_DLVR'}" *ngIf="element.active === 'PENDING_DLVR'">{{ element.active === 'PENDING_DLVR' ? 'รอส่ง' : '-' }}</span>
                  <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-danger' : element.active === 'CANCEL'}" (click)="openCancel(element)" *ngIf="element.active === 'CANCEL'">{{ element.active === 'CANCEL' ? 'ยกเลิก' : '-' }}</span>
                  <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-primary' : element.active === 'SUCCESS'}" *ngIf="element.active === 'SUCCESS'">{{ element.active === 'SUCCESS' ? 'สำเร็จ' : '-' }}</span>
               </ng-container>

               <ng-container *ngIf="element?.delivery?.deliveryMethod === 'PICK_UP'">
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-warning' : element.active === 'PENDING_DLVR'}" *ngIf="element.active === 'PENDING_DLVR'">{{ element.active === 'PENDING_DLVR' ? 'รอรับ' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-danger' : element.active === 'CANCEL'}" (click)="openCancel(element)" *ngIf="element.active === 'CANCEL'">{{ element.active === 'CANCEL' ? 'ยกเลิก' : '-' }}</span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-primary' : element.active === 'SUCCESS'}" *ngIf="element.active === 'SUCCESS'">{{ element.active === 'SUCCESS' ? 'สำเร็จ' : '-' }}</span>
             </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="delivery">
              <th mat-header-cell *matHeaderCellDef> วิธีรับสินค้า </th>
              <td mat-cell *matCellDef="let element">
                <span class="label font-bold m-b-0 m-t-0" *ngIf="element?.delivery?.deliveryMethod !== 'PICK_UP'"></span>
                <span class="label font-bold m-b-0 m-t-0" [ngClass]="{'label-pickup' : element?.delivery?.deliveryMethod === 'PICK_UP'}" *ngIf="element?.delivery?.deliveryMethod === 'PICK_UP'">P</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="payment">
              <th mat-header-cell *matHeaderCellDef> วิธีชำระเงิน </th>
              <td mat-cell *matCellDef="let element"> 
                <div *ngIf="element.orderSource === 'ONLINE' && element.paymentType !== 'MANUAL'">{{ element.paymentType === 'QR' ? 'QR' : 'Credit Card' }} ({{ element.paymentStatus }})</div>
                <div *ngIf="element.orderSource === 'ONLINE' && element.paymentType === 'MANUAL'">{{ element.payment }} ({{ element.paymentStatus }})</div>
                <div *ngIf="element.orderSource === 'MANUAL'">{{ element.delivery?.shippingManual?.sales_channel }} ({{ element.payment || '-' }})</div>
                <div *ngIf="element.orderSource === 'EXCHANGE'">{{ element.delivery?.shippingManual?.sales_channel }} ({{ element.payment || '-' }})</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="promocode">
              <th mat-header-cell *matHeaderCellDef> ใช้โค้ด </th>
              <td mat-cell *matCellDef="let element">{{ element?.promotion?.promo_code || '-'}}</td>
            </ng-container>

            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef> ใช้เครดิต </th>
              <td mat-cell *matCellDef="let element">{{ (element?.creditTotal | number) || '-'}}</td>
            </ng-container>

            <ng-container matColumnDef="tracking">
              <th mat-header-cell *matHeaderCellDef>TRACKING</th>
              <td mat-cell *matCellDef="let element; let i = index;">
                <span *ngIf="element?.delivery?.deliveryMethod !== 'PICK_UP'">{{ element.trackingNo || '-' }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="pickup">
              <th mat-header-cell *matHeaderCellDef>วันที่มารับ</th>
              <td mat-cell *matCellDef="let element; let i = index;">
                <ng-container *ngIf="element?.delivery?.deliveryMethod === 'PICK_UP'">
                  <div>
                      {{ element?.pickupDate || '-' | date: 'dd/MM/yyyy, h:mm a':'UTC+7' }}
                  </div>
                  <div *ngIf="!!element?.delivery?.confirmPickupDate">
                    ({{ element?.delivery?.confirmPickupDate || '-' | date: 'dd/MM/yyyy, h:mm a':'UTC+7' }})
                </div>
              </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-link">
                <a (click)="addTracking(element)" class="m-r-10 cursor-pointer text-dark" *ngIf="element.orderSource === 'ONLINE' || element?.isOnlineManual">Add#</a>
                <span *ngIf="element.orderSource !== 'ONLINE'" style="padding-left: 33px;"></span>
                <a (click)="gotoDetail(element)" class="m-r-10 cursor-pointer text-dark">View</a>
                <a (click)="cancelOrder(element)" class="m-r-10 cursor-pointer text-dark">Delete</a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="pagination" [length]="userLength" [pageSize]="tableOptions.pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageChanged($event)">
          </mat-paginator>
          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<app-blank *ngIf="this.userLength === 0"></app-blank>
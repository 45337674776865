
export enum LANGUAGE {
    EN = 'EN',
    TH = 'TH'
}

export enum ADDRESS_LANG {
    EN = 'EN',
    TH = 'TH'
}

export enum ORDER_SOURCE {
    ONLINE = 'ONLINE',
    MANUAL = 'MANUAL',
}

export enum PROMO_TYPE {
    PERCENTAGE = 'PERCENTAGE',
    BAHT_DISCOUNT = 'BAHT_DISCOUNT'
}

export enum AMOUNT_TYPE {
    PERCENTAGE = 'PERCENTAGE',
    FLAT = 'FLAT'
}

export const ALL_USER_TYPE = ['ADMIN', 'STAFF'];

export enum USER_TYPE {
    ADMIN = 'ADMIN',
    STAFF = 'STAFF',
    CUSTOMER = 'CUSTOMER'
}

export type PROMOTYPE = 'PERCENTAGE' | 'BAHT_DISCOUNT';
export const PROMOTYPE = {
    PERCENTAGE: 'PERCENTAGE' as PROMOTYPE,
    BAHTDISCOUNT: 'BAHT_DISCOUNT' as PROMOTYPE
};

export enum NOTIFICATION_TYPE {
    HEADER_BANNER = 'HEADER_BANNER',
}

export enum TAX_TYPE {
    CATEGORY = 'CATEGORY',
    TAG = 'TAG',
    COLOR = 'COLOR',
    COLLECTION = 'COLLECTION',
    BRAND = 'BRAND'
}

export enum MEMBER_TYPE {
    VIP = 'VIP',
    STANDARD = "STANDARD"
}

export const DEFAULT_SETTINGS = {
    defaultLanguage: LANGUAGE.EN
}

export const DEFAULT_BODY_PROPORTION = {
    bust: 0,
    waist: 0,
    hip: 0
}


export enum ACCEPTED_FILE_TYPE {
    APPLICATION = 'application',
    IMAGE = 'image'
}


export enum IMAGE_CONSTANT {
    PRODUCT_PATH = 'product-imgs/',
}

export const CLOTHE_SIZE = [
    { text: 'FREE SIZE', value: 'FREE_SIZE'},
    { text: 'MINI', value: 'MINI'},
    { text: 'XS', value: 'XS'},
    { text: 'S', value: 'S'},
    { text: 'M', value: 'M'},
    { text: 'L', value: 'L'},
    { text: 'XL', value: 'XL'},
    { text: '12-18M', value: 'M12_18'},
    { text: '18-24M', value: 'M18_24'},
    { text: '1-2Y', value: 'Y1_2'},
    { text: '2-3Y', value: 'Y2_3'},
    { text: '2-4Y', value: 'Y2_4'},
    { text: '3-4Y', value: 'Y3_4'},
    { text: '4-5Y', value: 'Y4_5'},
    { text: '4-6Y', value: 'Y4_6'},
    { text: '5-6Y', value: 'Y5_6'},
];


export const ALL_SHOE_SIZE: any[] = ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42'];

export enum PRODUCT_TYPE {
    CLOTHES = 'CLOTHES',
    SHOES = 'SHOES'
}

export enum PRODUCT_STATUS {
    DRAFT = 'DRAFT',
    PREVIEW = 'PREVIEW',
    SCHEDULE = 'SCHEDULE',
    PUBLISH = 'PUBLISH'
}

export type PAYMENTSTATUS = 'SUCCESS' | 'PENDING' | 'CANCEL';
export const PAYMENTSTATUS = {
    SUCCESS: 'SUCCESS' as PAYMENTSTATUS,
    PENDING: 'PENDING' as PAYMENTSTATUS,
    CANCEL: 'CANCEL' as PAYMENTSTATUS
};

export type ORDERSTATUS = 'SUCCESS' | 'PENDING_DLVR' | 'CANCEL';
export const ORDERSTATUS = {
    SUCCESS: 'SUCCESS' as ORDERSTATUS,
    PENDINGDLVR: 'PENDING_DLVR' as ORDERSTATUS,
    CANCEL: 'CANCEL' as ORDERSTATUS
};

export const SORT_LIST = [{
  value: 'title',
  text: 'name'
},{
  value: 'price',
  text: 'price'
},{
  value: 'sku',
  text: 'sku'
}, {
    value: 'createdAt',
    text: 'Last Created'
  }, {
  value: 'updatedAt',
  text: 'Last Updated'
}];

export const STATUS_LIST =  [{
  value: '',
  text: 'ALL'
},{
  value: 'DRAFT',
  text: 'draft'
},{
  value: 'SCHEDULE',
  text: 'schedule'
}, {
  value: 'PUBLISH',
  text: 'publish'
}, {
  value: 'INACTIVE',
  text: 'Inactive'
}]
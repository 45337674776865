
<div fxLayout="row wrap">
        <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
            <h2 class="page-title m-0">สมาชิก</h2>
		  </div>
</div>
<div fxLayout="row wrap">
  <div fxFlex="100" class="top-div">
    <div fxFlex="50">
      <mat-card>
        <mat-card-content>
          <table class="m-t-20" width="100%">
            <tr>
              <th class="font-bold">Top Spender</th>
              <th class="font-bold">ยอดที่ใช้</th>
            </tr>
            <tr *ngFor="let dd of dashboard?.topSpenderList">
              <td>{{dd?.firstName}} {{dd?.lastName}}</td>
              <td>{{dd?.totalSpent || 0 | number }}</td>
            </tr>
          </table>
          </mat-card-content>
      </mat-card>
    </div>
    <div fxFlex="25">
      <mat-card style="padding:30px !important;">
        <mat-card-content style="min-height: 100px;">
          <mat-card-title>สมาชิกในเดือนนี้</mat-card-title>   
          <h2 class="show-top">{{ this.dashboard?.totalBDayInMonth || 0 | number  }} คน</h2>
          </mat-card-content>
      </mat-card>
    </div>
    <div fxFlex="25">
      <mat-card style="padding:30px !important;">
        <mat-card-content style="min-height: 100px;">
          <mat-card-title>สมาชิก VIP</mat-card-title>
          <h2 class="show-top">{{ this.dashboard?.totalVip || 0 | number }} คน</h2>
          </mat-card-content>
      </mat-card>
    </div>
  </div>
    <div fxFlex="100">
      <mat-card>
        <mat-card-content>
          <div class="align-items-center">
            <div fxFlex="20" class="text-center">
              <mat-form-field>
                <mat-label>ประเภทค้นหา</mat-label>
                <select matNativeControl (change)="selectedCustomerSearch($event)" [(ngModel)]="selectedCustomer">
                  <option value="username">username</option>
                  <option value="firstname">ชื่อ</option>
                  <option value="lastname">นามสกุล</option>
                  <option value="contactNum">เบอร์</option>
                </select>
              </mat-form-field>
            </div>
            <div fxFlex="60" class="text-center m-l-10">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ค้นหารายชื่อ">
                <mat-icon matSuffix (click)="applyFilter($event.target.value)">search</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex="20" class="text-center">
              <button mat-raised-button (click)="openDialog('Add',{})" color="primary">เพิ่มสมาชิก</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <app-blank *ngIf="this.userLength === 0"></app-blank>
  <div fxLayout="row wrap" *ngIf="this.userLength !== 0">
    <div fxFlex="100">
      <mat-card>
        <mat-card-content>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">

              <ng-container matColumnDef="in">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let item; let i = index;"> 
                  {{ i + (tableOptions.pageNumber * tableOptions.pageSize) + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> รห้สสมาชิก </th>
                <td mat-cell *matCellDef="let item; let i = index;"> {{ '000' + item.id }} </td>
              </ng-container>
  
              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> ขื่อ </th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <!-- <img class="mat-card-avatar" [src]='element.imagePath'> -->
                    <div class="m-l-15">
                      <a (click)="openDialog('View',element)" class="cursor-pointer text-dark"><p class="font-bold m-b-0 m-t-0">{{ element.firstName }} {{ element.lastName }}</p></a>
                      <!-- <small>{{element.Position}}</small> -->
                    </div>
                  </div>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> ชื่อเล่น </th>
                <td mat-cell *matCellDef="let element"> {{element.nickName || '-'}} </td>
              </ng-container>
  
              <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> เบอร์ </th>
                <td mat-cell *matCellDef="let element"> {{element.contactNum}} </td>
              </ng-container>

              <ng-container matColumnDef="lineid">
                <th mat-header-cell *matHeaderCellDef> วันเกิด </th>
                <td mat-cell *matCellDef="let element"> {{element.dob | date: 'dd/MM/yyyy' }} </td>
              </ng-container>

              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> คะแนนสะสม </th>
                <td mat-cell *matCellDef="let element"> {{ element.Member?.memberScore | number }} </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> member type </th>
                <td mat-cell *matCellDef="let element"> {{ element.Member?.memberType === 'VIP' ? 'VIP' : '-' }} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> สถานะ </th>
                <td mat-cell *matCellDef="let element">  <mat-icon>{{ element.active ? 'check' : 'clear '}}</mat-icon></td>
              </ng-container>

              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> เวลาสมัคร </th>
                <td mat-cell *matCellDef="let element">{{element.createdAt | date: 'dd/MM/yyyy':'UTC+7' }}</td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="action-link">
                  <a (click)="openDialog('Reset',element)" class="m-r-10 cursor-pointer">#password</a>
                  <a (click)="openDialog('Score',element)" class="m-r-10 cursor-pointer">#score</a>
                  <a (click)="openDialog('Address',element)" class="m-r-10 cursor-pointer">#address</a>
                  <a (click)="openDialog('View',element)" class="m-r-10 cursor-pointer text-dark">View</a>
                  <a (click)="openDialog('Update',element)" class="m-r-10 cursor-pointer text-dark">Edit</a>
                  <!-- <a (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer text-dark">
                    <mat-icon style="color: #fc4b6c;">delete</mat-icon>
                  </a> -->
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator class="pagination" [length]="userLength" [pageSize]="tableOptions.pageSize"
              [pageSizeOptions]="pageSizeOptions" (page)="onPageChanged($event)">
            </mat-paginator>
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {AuthService as ApiAuthService} from '../../../client/api/auth.service';
import { TableDataSource } from '../common/interfaces/common.interface';
import { TaxonomyService } from '../services/taxonomy.service';
import { AlertService } from '../services/alert.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FilePondInitialFile } from 'filepond';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { TAXTYPE } from '../../../client';

@Component({
  selector: 'app-admin-taxonomy',
  templateUrl: './admin-taxonomy.component.html',
  styleUrls: ['./admin-taxonomy.component.scss']
})
export class AdminTaxonomyComponent implements OnInit {
  searchText: any;
  displayedColumns: string[] = ['index', 'title', 'title_th', 'tax_type','status', 'indexNumber', 'action'];
  dataSource = new MatTableDataSource();
  tableOptions: TableDataSource;
  pageSizeOptions = ['10', '30', '50'];
  taxonomyLength = 0;
  taxTypeLists = ['CATEGORY', 'TAG', 'COLOR', 'COLLECTION', 'BRAND'];
  taxType: TAXTYPE = 'COLOR';
  @ViewChild('myPond') myPond: any;
  @ViewChild(MatPaginator, { static : false} ) paginator: MatPaginator;
  constructor(private dialog: MatDialog,
              private apiAuthService: ApiAuthService,
              private taxonomyService: TaxonomyService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.setupTableOptions();
    this.getTaxonomy();
  }

  setupTableOptions() {
    this.tableOptions = {
      pageNumber: 0,
      pageSize: 10,
      filter: null,
      sortOrder: 'desc'
    };
  }

  onPageChanged(pageObj: PageEvent) {
    if (pageObj.pageIndex) {
      this.tableOptions.pageNumber = pageObj.pageIndex;
  } else {
      this.setupTableOptions();
  }
    this.tableOptions.pageSize = pageObj.pageSize;
    this.getTaxonomy(pageObj.pageIndex);
  }

  async getTaxonomy(pageIndex?: number) {
    if (!!pageIndex) {
      this.tableOptions.pageNumber = pageIndex;
    } else {
      this.tableOptions.pageNumber = 0;
    }
    const data = await this.taxonomyService.getTaxonomyBySearch(this.taxType, this.tableOptions) as any;
    if(data) {
      this.dataSource = new MatTableDataSource(data.taxonomies);
      this.dataSource._updateChangeSubscription();
      this.taxonomyLength = data.listLength;
      if(this.tableOptions.pageNumber === 0) {
        this.paginator?.firstPage();
    }
    }
  }

  onSelectionChange(e) {
    this.setupTableOptions();
    this.getTaxonomy();
  }

  async applyFilter(data) {
    this.setupTableOptions();
    if (data.length === 0) {
          this.getTaxonomy(0);
      }

    if (data.length > 2) {
      const result = await this.taxonomyService.getTaxonomyByName(data, this.tableOptions) as any;
      if (result) {
          this.dataSource = new MatTableDataSource(result.payload.taxonomies);
          this.dataSource._updateChangeSubscription();
          if(this.tableOptions.pageNumber === 0) {
            this.paginator?.firstPage();
          }
          if(this.paginator) {
            if (this.paginator.pageIndex) {
              this.paginator.pageIndex = 0;
            }
          }
          if (result.payload.listLength === 0) {
              this.taxonomyLength = 0;
          } else {
              this.taxonomyLength = result.payload.listLength;
          }
      } else {
          this.taxonomyLength = 0;
      }
    }
  }

  openDialog(action: string, obj: any) {
    obj.action = action;
    const dialogRef = this.dialog.open(TaxonomyDialogContent, {
        data: obj,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!!!result) {
        return;
      }
        if (result.event === 'Add') {
            this.addRowData(result.data);
        } else if (result.event === 'Update') {
            this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
            this.deleteRowData(result.data);
        }
    });
  }

  // tslint:disable-next-line:variable-name
  async addRowData(row_obj: any) {
    let saveData = {
      id: row_obj.id,
      title: row_obj.title,
      title_th: row_obj.title_th,
      tax_type: row_obj.tax_type,
      isActive: row_obj.isActive,
      index: row_obj.index
    };
    if(row_obj.tax_type === 'COLOR' && row_obj.filesAdd.length > 0) {
      saveData['TaxPrimaryImgId'] = row_obj.filesAdd[0].id
    }

    const data = await this.taxonomyService.createCurrency(saveData);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getTaxonomy();
     }

  }
  // tslint:disable-next-line:variable-name
  async updateRowData(row_obj: any) {
    const req = {
      id: row_obj.id,
      title: row_obj.title,
      title_th: row_obj.title_th,
      isActive: row_obj.isActive,
      index: row_obj.index
    }

    if(row_obj.tax_type === 'COLOR' && row_obj.filesAdd?.length > 0) {
      req['TaxPrimaryImgId'] = row_obj.filesAdd[0].id;
    } else {
      req['TaxPrimaryImgId'] =  row_obj.TaxPrimaryImgId;
    }

    const data = await this.taxonomyService.updateCurrency(row_obj.id, req);
    if (data) {
        this.alertService.success('ทำรายการสำเร็จ');
        this.getTaxonomy(this.tableOptions.pageNumber);
    }
  }
  // tslint:disable-next-line:variable-name
  async deleteRowData(row_obj: any) {
    const data = await this.taxonomyService.deleteCurrencyById(row_obj.id);
    if (data) {
      this.alertService.success('ทำรายการสำเร็จ');
      this.getTaxonomy();
    }
  }

}




@Component({
  // tslint:disable-next-line: component-selector
  selector: 'taxonomy-dialog',
  templateUrl: 'taxonomy-dialog.html',
})
// tslint:disable-next-line: component-class-suffix
export class TaxonomyDialogContent {
  action: string;
  // tslint:disable-next-line:variable-name
  local_data: any;
  countries;
  filteredStates: Observable<[]>;
  stateCtrl = new FormControl();
  filesAdd = [];
  pondOptions: any;
  pondFiles: Array<FilePondInitialFile | Blob | string> = [];
  files = [];
  taxTypeLists = ['CATEGORY', 'TAG', 'COLOR', 'COLLECTION', 'BRAND'];
  constructor(
      public dialogRef: MatDialogRef<TaxonomyDialogContent>,
      public authService: AuthService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      if(data.action === 'Add') {
        data.isActive = true; 
      }
      this.local_data = { ...data };
      this.action = this.local_data.action;
      this.setupFilePond();
      this.loadImages(data);
  }

  setupFilePond() {
    this.authService.getToken().then(token => {
      const pondOptionMaster: any = {
          dropOnPage: true,
          className: 'my-filepond',
          labelIdle: 'วางไฟล์ที่นี่',
          acceptedFileTypes: ['image/jpeg', 'image/png'],
          allowMultiple: true,
          name: 'file',
          server: {
              url: environment.backendUri,
              process: {
                  // url: '/image/product/upload',
                  url: '',
                  method: 'POST',
                  withCredentials: false,
                  headers: {
                      Authorization: `Bearer ${token}`
                  },
                  timeout: 7000,
                  onload: null,
                  onerror: null,
                  ondata: null
              }
          },
          allowImagePreview: true,
          imagePreviewMinHeight: 50,
          imagePreviewMaxHeight: 100,
      };

      this.pondOptions = pondOptionMaster;
      this.pondOptions['server']['process']['url'] = '/image/product/upload';
  });
  }

  loadImages(images) {
    if (!images) {
        return;
    }

    this.pondFiles = [{
      source: images.TaxPrimaryImgId,
      options: {
          type: 'local',
          file: {
              name: 'image',
          },
          metadata: {
              poster: `${environment.backendUri}/image/` + images.TaxPrimaryImgId,
          },
      },
  }];
}

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'Cancel' });
  }

  setAll(event) {
    this.local_data.isActive = event;
  }

  updateCountryCode(event, country) {
    this.local_data.country_code = country.code;
  }

  checkDisabled(data) {
    if (data.action === 'Add') {
        if (!!data.title && data.title_th) {
            return false;
        } else {
            return true;
        }
    } else if(data.action === 'Update') {
      if (!!data.title && data.title_th) {
        return false;
      } else {
          return true;
      }
    } else {
        return false;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  pondHandleInit() {
  }

  pondHandleAddFile(event: any, action?: any) {
  }

  async pondHandleRemoveFile(event: any, action) {
  }

  pondHandleProcessFile(event: any) {
    this.local_data.filesAdd = [];
    console.debug('pondHandleProcessFile :', event);

    const {error, file, pond} = event;
    if (error) {
        console.error('Process file error :', error);
        return;
    }

    // Append file
    // Get file response -- filepond put it in serverId :(
    const fileResp = JSON.parse(file.serverId);
    if (!fileResp.success) {
        console.error('file upload resp error :', fileResp);
        return;
    }

    const {
        payload: {image},
    } = fileResp;
    this.local_data.filesAdd.push(image);

  }

  onSelectionChange(e) {
  }

  
}

<h2 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h2>
<mat-dialog-content class="mat-typography" *ngIf="action === 'Add' || action === 'Update'">
  <form #userForm="ngForm">
      <mat-form-field>
        <input type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
          placeholder="title">
      </mat-form-field>

      <mat-form-field>
        <input type="text" matInput required id="promo_code" name="promo_code" [(ngModel)]="local_data.promo_code"
          placeholder="promo_code">
      </mat-form-field>

      <mat-form-field>
        <mat-label>เลือกประเภทของส่วนลด</mat-label>
        <mat-select [(ngModel)]="local_data.promotion_type" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let country of promoType" [value]="country.value">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field>
        <input type="number" matInput required id="total_discount_amt" name="total_discount_amt" [(ngModel)]="local_data.total_discount_amt"
          placeholder="discount_amt">
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput required id="promo_limit" name="promo_limit" [(ngModel)]="local_data.promo_limit"
          placeholder="qty">
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput required id="promo_limit_per_user" name="promo_limit_per_user" [(ngModel)]="local_data.promo_limit_per_user"
          placeholder="limit per user">
      </mat-form-field>

      <ejs-daterangepicker id='daterangepicker' [format]="'dd-MMM-yy'" placeholder='Select a range' [startDate]='start' [endDate]='end' (change)="getDater($event)"></ejs-daterangepicker>

  </form>
</mat-dialog-content>
<ng-template *ngIf="action === 'Delete'">
  Sure to delete <b>{{local_data.title}}</b>?
</ng-template>
<div mat-dialog-actions align="end">
  <button (click)="doAction()" mat-flat-button color="warn" [disabled]="checkDisabled(local_data)">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
